<template>
    <div
        class="files_wrap flex flex-col" 
        ref="filesWrap"
        :class="[loading.global && 'light_wrap', !isMobile && 'h-full']">
        <div>
            <div class="lg:flex justify-between mb-2">
                <a-spin 
                    v-if="loading.global" 
                    class="files_global_spin"/>
                <FileBreadcrumbs
                    :rootId="rootId"
                    :sourceId="sourceId"
                    :isMyFiles="isMyFiles"
                    :setCurrentSource="setCurrentSource" />
                <div class="flex lg:mt-0 justify-between items-center" :class="isMobile ? 'mt-1' : 'mt-2'">
                    <div class="file_count mr-2">
                        <template v-if="isSearch">
                            <div>Результатов поиска: {{ searchCount }}</div>
                        </template>
                        <template v-else-if="!isTrash">
                            <a-spin v-if="loading.count && !attaching" />
                            <template v-else>
                                <span v-if="showFoldersCount" class="mr-2">
                                    Папок: {{ count.folders }}
                                </span>
                                <span>
                                    Файлов: {{ count.files }}
                                </span>
                            </template>
                        </template>
                    </div>
                    <a-radio-group 
                        v-model="activeViewType"
                        size="default"
                        :class="isMobile && 'mobile_list'"
                        class="files_view_type"
                        @change="changeViewType">
                        <a-radio-button 
                            v-for="view in viewTypes" 
                            :key="view.name" 
                            :value="view.name">
                            <i class="fi" :class="view.icon"></i>
                        </a-radio-button>
                    </a-radio-group>
                </div>
            </div>
            <template v-if="isMyFiles">
                <template v-if="!isMobile">
                    <div class="flex">
                        <PageFilter 
                            v-if="showFilter"
                            class="w-full"
                            :model="myFilesModel"
                            :getPopupContainer="getPopupContainer"
                            size="large"
                            :page_name="sourceId" />
                        <DropdownSort
                            class="ml-3"
                            :model="myFilesModel" 
                            :page_name="sourceId" />        
                    </div>
                </template>
            </template>
            <a-auto-complete
                v-else
                v-model="searchText"
                :data-source="dataSource"
                style="width: 100%"
                placeholder="Поиск файлов и папок"/>
            <!-- Actions -->
            <div
                class="flex justify-between items-center" 
                :class="isMobile ? 'mt-1 mb-1' : 'mt-2 mb-2'">
                <template v-if="showActions">
                    <div class="file_actions">
                        <template v-if="!isTrash">
                            <a-tooltip 
                                class="action_icon"
                                v-if="canPasteFiles && isFounder">
                                <a-button 
                                    type="ui"
                                    flaticon
                                    icon="fi-rr-copy-alt"
                                    @click="pasteFiles" />
                                <template slot="title">                            
                                    Вставить в текущую папку
                                </template>
                            </a-tooltip>
                            <a-tooltip 
                                class="action_icon"
                                v-if="isSelected && isFounder && createFounder">
                                <template slot="title">                            
                                    Переместить выделенное
                                </template>
                                <a-button
                                    type="ui"
                                    flaticon
                                    icon="fi-rr-file-export"
                                    @click="cutFiles" />
                            </a-tooltip>
                            <a-tooltip 
                                class="action_icon"
                                v-if="isSelected && isFounder && !isAttachingToFiles">
                                <template slot="title">                            
                                    {{ isMyFiles ? 'Удалить выделенное' : 'Открепить выделенное' }}
                                </template>
                                <a-button
                                    flaticon
                                    type="ui"
                                    class="text_red"
                                    icon="fi fi-rr-trash"
                                    @click="confirmDelete" />
                            </a-tooltip>
                        </template>
                    </div>
                </template>
                <template v-if="isMyFiles && !isAttachingToFiles">
                    <div class="file_trash">
                        <template
                            v-if="isTrash">
                            <div class="flex">
                                <a-button
                                    v-if="fileList.length"
                                    class="mr-2"
                                    @click="clearTrash">
                                    Очистить
                                </a-button>
                                <a-button 
                                    v-if="!isMobile"
                                    @click="setCurrentRoot('my_files')"
                                    class="flex items-center">
                                    <i class="btn_icon fi fi-rr-arrow-small-left"></i>
                                    <span class="ml-2">
                                        Мои файлы
                                    </span>
                                </a-button>
                            </div>
                        </template>
                        <template v-else>
                            <a-button
                                v-if="!isMobile"
                                @click="setCurrentRoot('trash')"
                                class="flex items-center">
                                <i class="btn_icon fi fi-rr-trash"></i>
                                <span class="ml-2">
                                    Корзина
                                </span>
                            </a-button>
                        </template>
                    </div>
                </template>
                <FileCreate 
                    v-if="isFounder && showFileCreate"
                    :rootId="rootId"
                    :sourceId="sourceId"
                    :isMyFiles="isMyFiles"
                    viewType="button"
                    :showDeviceUpload="showDeviceUpload"
                    :createFounder="createFounder"
                    :oneUpload="oneUpload"
                    :getCreateContainer="getDropContainer"
                    :fileDragCreate="fileDragCreate"
                    :mobileApp="mobileApp"
                    :attachmentFiles="attachmentFiles"
                    :class="isMobile ? 'mb-2' : 'ml-auto'" />
            </div>
        </div>


        <!-- View type -->
        <div class="grow overflow-hidden" :class="!isMobile && 'h-full'">
            <component 
                :key="sourceId"
                :is="viewType"
                :zIndex="zIndex"
                :fileList="fileList"
                :removeFiles="removeFiles"
                :restoreFiles="restoreFiles"
                :fileDragCreate="fileDragCreate"
                :isSearch="isSearch"
                
                :rootId="rootId"
                :sourceId="sourceId"
                :oneUpload="oneUpload"
                :attachingRootId="attachingRootId"
                :attachingSourceId="attachingSourceId"
                :attaching="attaching"
                :widgetEmbed="widgetEmbed"
                :isMyFiles="isMyFiles"
                :isTrash="isTrash"
                :mobileApp="mobileApp"
                :createFounder="createFounder"
                :showFileCreate="showFileCreate && !isTrash"
                
                :selectedFiles="selectedFiles"
                :cuttedFiles="cuttedFiles"
    
                :setCurrentSource="setCurrentSource"
    
                :isFounder="isFounder"
                :isStudent="isStudent">
                <template 
                    v-if="sourceId"
                    #infiniteLoading>
                    <InfiniteLoading
                        ref="infiniteFileLoading"
                        :key="isSearch ? `search-infinity-${sourceId}` : `infinity-${sourceId}`"
                        @infinite="infiniteFileHandler">
                        <div slot="spinner" >
                            <a-spin class="mt-4"/>
                        </div>
                        <div slot="no-more"></div>
                        <div slot="no-results"></div>
                    </InfiniteLoading>
                </template>
            </component>
        </div>
        <div v-if="isMobile && isMyFiles" class="float_add">
            <DropdownSort
                class="mb-2"
                :model="myFilesModel" 
                :page_name="sourceId" />  
            <div class="filter_slot">
                <PageFilter
                    class="w-full"
                    :model="myFilesModel"
                    :getPopupContainer="getPopupContainer"
                    size="large"
                    :page_name="sourceId" />
            </div>
            <template v-if="isMyFiles && !isAttachingToFiles" >
                <a-button 
                    v-if="isTrash"
                    flaticon
                    shape="circle"
                    icon="fi-rr-arrow-small-left"
                    @click="setCurrentRoot('my_files')" />
                <a-button
                    v-else
                    flaticon
                    shape="circle"
                    icon="fi-rr-trash"
                    @click="setCurrentRoot('trash')" />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import eventBus from '@/utils/eventBus.js'

import DropdownSort from './DropdownSort.vue'

import myRolesProps from '../mixins/myRolesProps'
import attachingSourcesProps from '../mixins/attachingSourcesProps'

import FileBreadcrumbs from './FileBreadcrumbs.vue'
import FileCreate from './FileCreate.vue'
import PageFilter from '@/components/PageFilter'

const PREFIX_RESTORE = 'restore-'
export default {
    name: 'Files',
    mixins: [attachingSourcesProps, myRolesProps],
    components: {
        FileCreate,
        FileBreadcrumbs,
        PageFilter,
        InfiniteLoading,
        DropdownSort
    },
    props: {
        rootId: {
            type: [String, Number],
            default: ''
        },
        isMyFiles: {
            type: Boolean,
            default: false
        },
        showFileCreate: {
            type: Boolean,
            default: true
        },
        showFoldersCount: {
            type: Boolean,
            default: true
        },
        attachmentFiles: {
            type: Array,
            default: () => []
        },
        attaching: {
            type: Boolean,
            default: false
        },
        isRestoring: {
            type: Boolean,
            default: false
        },
        restoreDest: {
            type: Array,
            default: () => []
        },
        widgetEmbed: {
            type: Boolean,
            default: false
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        mobileApp: {
            type: Boolean,
            default: false
        },
        changeSelect: {
            type: Function,
            default: () => {}
        },
        showFilter: {
            type: Boolean,
            default: true
        },
        fileDragCreate: {
            type: Boolean,
            default: true
        },
        zIndex: {
            type: Number,
            default: 1000
        },
        showDeviceUpload: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: {
                global: false,
                files: false,
                count: false,
            },
            fileListLoading: false,
            currentRootId: this.rootId,

            isSearch: false,
            searchText: '',
            dataSource: [],

            sourceId: null,
            activeViewType: 'list',
            count: {
                files: 0,
                folders: 0
            },
            pageSize: 15,
            selectedFiles: {
                from: null,
                list: []
            },
            cuttedFiles: {},
            myFilesModel: 'common.File'
        } 
    },
    computed: {
        ...mapState({
            files: state => state.files.files,
            config: state => state.config.config
        }),
        ...mapGetters('files', [
            'getFilesViewType', 
        ]),
        showActions() {
            return (this.canPasteFiles && this.isFounder) || 
                (this.isSelected && this.isFounder && this.createFounder) || 
                (this.isSelected && this.isFounder && !this.isAttachingToFiles)
        },
        getDropContainer() {
            return this.$refs.filesWrap
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        defaultView() {
            return this.config?.files_setting?.default_view || 'list'
        },
        viewTypes() {
            return this.config?.files_setting?.available_views?.length ? this.config.files_setting.available_views : [
                {
                    icon: "fi-rr-list",
                    name: "list"
                },
                {
                    icon: "fi-rr-apps",
                    name: "grid"
                }
            ]
        },
        fileList() {
            if (!this.sourceId && !this.rootId) {
                return this.attachmentFiles
            }
            if (this.isSearch) 
                return this.files['found_files']?.results || []
            return this.files[this.sourceId]?.results || []
        },
        searchCount() {
            return this.files['found_files']?.count || 0
        },
        nextPage() {
            const defaultPage = 1
            const sourceId = this.isSearch ? 'found_files' : this.sourceId 
            const currentPage = this.files[sourceId]?.page
            return currentPage ? (currentPage + 1) : defaultPage
        },
        currentNext() {
            const sourceId = this.isSearch ? 'found_files' : this.sourceId
            if(this.files[sourceId]?.next === null)
                return null
            return true
        },
        isFolder() {
            const rootIds = ['trash', 'my_files', this.currentRootId]
            return !rootIds.includes(this.sourceId.replace(PREFIX_RESTORE, ''))
            // if(this.isTrash) {
            //     if('trash' !== this.sourceId)
            //         return true
            // }
            // else if(this.isMyFiles) {
            //     if('my_files' !== this.sourceId)
            //         return true
            // } else if(this.rootId !== this.sourceId)
            //     return true
            // return false
        },
        isTrash() {
            return this.currentRootId === "trash"
        },
        viewType() {
            switch(this.activeViewType) {
            case 'grid': 
                return () => import('./ViewTypes/FileGrid.vue')
            case 'list': 
                return () => import('./ViewTypes/FileList.vue')
            default:
                return () => import('./ViewTypes/FileList.vue')
            }
        },
        isSelected() {
            return this.selectedFiles.list.length
        },
        isCutted() {
            return this.cuttedFiles?.list?.length 
        },
        canPasteFiles() {
            if(this.isCutted) {
                if(this.sourceId !== this.cuttedFiles.from) {
                    for(const file of this.cuttedFiles.list) {
                        if(file.id === this.sourceId)
                            return false
                    }
                    return true
                }
            }
            return false 
        },
        isAttachingToFiles() {
            return !!this.attachingRootId
        },
        infiniteFileHandler() {
            return this.isSearch ? this.getFoundFileList : this.getFileList
        }
    },
    watch: {
        searchText: async function(newText) {
            if(newText.length > 2) {
                

                this.debouncedRestartSearch()
                // await this.setCurrentSource('found_files')

            } else {
                this.isSearch = false
                await this.setCurrentSource(this.sourceId)
            }
        },
        isSelected(val) {
            this.changeSelect(val)
        }
    },
    async created() {
        this.debouncedRestartSearch = _.debounce(this.restartSearch, 1000)

        this.initViewType()

        if(this.attachmentFiles.length) {
            this.selectedFiles.list.splice(0)
            this.selectedFiles.list.push(...this.attachmentFiles)
        }

        const query = JSON.parse(JSON.stringify(this.$route.query))

        const defaultSourceId = query.folder || this.rootId
        let initSourceId = this.isMyFiles ? 'my_files' : defaultSourceId 
        if(this.isRestoring)
            initSourceId = PREFIX_RESTORE + initSourceId
        await this.setCurrentSource(initSourceId)
    },
    methods: {
        ...mapActions('files', [
            'getFiles', 
            'getFoundFiles', 
            'getMyFiles', 
            'getTrashFiles',
            'getFoldersForRestore',
            'deleteFiles', 
            'deleteMyFiles',
            'fileCount', 
            'myFileCount', 
            'moveFiles', 
            'moveMyFiles',
            'uploadFiles',
            'clearTrash'
        ]),
        getPopupContainer() {
            return this.$refs.filesWrap
        },
        async getFileList($state) {
            if(!this.fileListLoading) {
                if(this.currentNext) {
                    try {
                        this.fileListLoading = true
                        this.loading.files = true
                        const folderId = this.isFolder ? this.sourceId.replace(PREFIX_RESTORE, '') : null
                        if(this.isMyFiles) {
                            if(this.isRestoring) {
                                await this.getFoldersForRestore({
                                    folderId: folderId, 
                                    params: {
                                        page_size: this.pageSize,
                                        page: this.nextPage,
                                        page_name: this.sourceId
                                    }
                                })
                            }
                            else if(this.isTrash)
                                await this.getTrashFiles({
                                    folderId: folderId, 
                                    params: {
                                        page_size: this.pageSize,
                                        page: this.nextPage,
                                        page_name: this.sourceId
                                    }
                                })
                            else
                                await this.getMyFiles({
                                    folderId: folderId, 
                                    params: {
                                        page_size: this.pageSize,
                                        page: this.nextPage,
                                        page_name: this.sourceId
                                    }
                                })
                        } else {
                            await this.getFiles({
                                rootId: this.rootId, 
                                folderId: folderId,
                                params: {
                                    page_size: this.pageSize,
                                    page: this.nextPage,
                                    page_name: this.sourceId
                                }
                            })
                        }
                    
                        if(this.currentNext) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                    } catch(error) {
                        this.$message.error('Ошибка загрузки файлов')
                        console.log(error)
                    } finally {
                        this.loading.files = false
                        this.fileListLoading = false
                    }
                } else {
                    $state.complete()
                }
            }
        },
        async getFoundFileList($state) {
            if(!this.fileListLoading) {
                if(this.currentNext) {
                    try {
                        this.fileListLoading = true
                        this.loading.files = true
    
                        const folderId = this.isFolder ? this.sourceId : null
                        if(this.isMyFiles) {
                            // await this.getMyFiles({ 
                            //     params: {
                            //         page_size: this.pageSize,
                            //         page: this.nextPage,
                            //         page_name: this.sourceId
                            //     }
                            // })
                        } else {
                            await this.getFoundFiles({
                                rootId: this.rootId, 
                                folderId: folderId,
                                params: {
                                    page_size: this.pageSize,
                                    page: this.nextPage,
                                    page_name: this.sourceId,
                                    search: this.searchText,
                                    folder: folderId
                                }
                            })
                        }
                        if(this.currentNext) {
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                    } catch(error) {
                        this.$message.error('Ошибка загрузки файлов')
                        console.log(error)
                    } finally {
                        this.loading.files = false
                        this.fileListLoading = false
                    }
                } else {
                    $state.complete()
                }
            }
        },
        async removeFiles({files = [], folders = []}) {


            this.loading.global = true

            if(files)
                files = Array.isArray(files) ? files : [files]
            if(folders)
                folders = Array.isArray(folders) ? folders : [folders]

            const folderId = this.isFolder ? this.sourceId : null
            if(this.isTrash) {
                this.$store.dispatch('files/deleteFilesFromTrash', {
                    folderId: folderId,
                    folders: folders,
                    files: files
                })
            } else if(this.isMyFiles) {
                this.deleteMyFiles({
                    folderId: folderId,
                    folders: folders,
                    files: files
                })
            } else {
                await this.deleteFiles({
                    rootId: this.rootId,
                    folderId: folderId,
                    files: files,
                    folders: folders
                })
            }

            this.loading.global = false
        },
        async setCurrentSource(sourceId, rootId = null) {
            if(this.isRestoring) {
                this.restoreDest.splice(0)
                this.restoreDest.push(sourceId)
                if (!sourceId.includes(PREFIX_RESTORE)) 
                    sourceId = PREFIX_RESTORE + sourceId
            } 

            if(this.isSearch) {
                this.isSearch = false
                this.searchText = ''
            }
            if(sourceId === 'trash')
                this.currentRootId = 'trash'
            if(sourceId === 'my_files')
                this.currentRootId = 'my_files'
            if(rootId !== null)
                this.currentRootId = rootId

            this.sourceId = sourceId
            
            this.selectedFiles = {
                from: null,
                list: []
            }
            
            if(!this.isAttachingToFiles && !this.attaching) {
                this.setCurrentRouteQuery()
            }
            if(!this.isTrash) {
                if (this.sourceId) {
                    await this.updateFileCount()
                }
            }

        },
        async setCurrentRoot(rootId) {
            this.currentRootId = rootId
            this.setCurrentSource(rootId)
        },
        setCurrentRouteQuery() {
            const query = JSON.parse(JSON.stringify(this.$route.query))
            
            if(this.isFolder) {
                if(query.folder !== this.sourceId) {
                    query.folder = this.sourceId
                    this.$router.replace({ query })
                }
            } else if(query.folder) {
                delete query['folder']
                this.$router.replace({ query })
            }
        },
        changeViewType(event) {
            const viewType = event.target.value
            this.$store.commit('files/SET_FILE_VIEW_TYPE', viewType)

        },
        initViewType() {
            let viewType = this.getFilesViewType
            if(!viewType)
                viewType = this.defaultView || 'list'
            this.activeViewType = viewType 
        },
        async updateFileCount() {
            this.loading.count = true

            let count = 0
            const folderId = this.isFolder ? this.sourceId.replace(PREFIX_RESTORE, '') : null
            if(this.isMyFiles)
                count = await this.myFileCount({ 
                    folderId: folderId
                })
            else {
                count = await this.fileCount({
                    rootId: this.rootId,
                    folderId: folderId
                })
            }

            this.count = count

            this.loading.count = false
        },
        async pasteFiles() {
            const where = this.isFolder ? this.sourceId : null
            try {
                if(this.isMyFiles) {
                    await this.moveMyFiles({
                        from: this.cuttedFiles.from,
                        where: where,
                        files: this.cuttedFiles.list,
                    })
                } else {
                    await this.moveFiles({
                        rootId: this.rootId,
                        from: this.cuttedFiles.from,
                        where: where,
                        files: this.cuttedFiles.list,
                    })
                }
                this.$message.success("Файлы успешно перемещены")
            } catch(error) {
                this.$message.error("Ошибка перемещения файлов: ", error)
            } finally {
                this.cuttedFiles = {}
            }
        },
        cutFiles() {
            this.cuttedFiles = {
                from: this.selectedFiles.from,
                list: [...this.selectedFiles.list] 
            }
        },
        async attachSelected() {
            this.attachmentFiles.push(...this.selectedFiles.list) 
            if(this.isAttachingToFiles) {
                const fileIListId = this.attachmentFiles.map(file => file.id)

                const folderId = this.attachingSourceId !== this.attachingRootId ? this.attachingSourceId : null
                const alreadyExistFiles = await this.uploadFiles({
                    files: fileIListId, 
                    rootId: this.attachingRootId, 
                    folderId: folderId
                })
                alreadyExistFiles.forEach(file => 
                    this.$message.warning(`Файл "${file.name}" уже прикреплен`)
                )
            }
            this.clearSelected()
            this.$emit('closeParentModal')
        },
        clearSelected() {
            this.selectedFiles.list.splice(0)
        },
        clearAttachments() {
            this.attachmentFiles.splice(0)
        },
        async confirmDelete() {
            const self = this
            this.$confirm({
                title: `Вы уверены что хотите ${self.isMyFiles ? 'удалить' : 'открепить'} файл?`,
                okText: 'Да',
                okType: 'danger',
                getContainer: this.getPopupContainer(),
                cancelText: 'Нет',
                async onOk() {
                    await self.deleteSelected()
                },
            })
        },
        async deleteSelected() {
            const files = [],
                folders = []
            for(const file of this.selectedFiles.list) 
                if(file.obj_type === 'folder')
                    folders.push(file)
                else 
                    files.push(file)

            await this.removeFiles({folders: folders, files: files})
            this.selectedFiles.list.splice(0)
        },
        restartSearch() {
            if(this.files['found_files']) {
                this.clearFoundFiles()
            }
            this.resetInfiniteLoading()        
            this.isSearch = true
        },
        clearFoundFiles() {
            const foundFiles = this.files['found_files']
            foundFiles.results.splice(0)
            foundFiles.page = 0
            foundFiles.next = true
        },
        resetInfiniteLoading() {
            this.$nextTick(() => {
                if(this.$refs.infiniteFileLoading)
                    this.$refs.infiniteFileLoading.stateChanger.reset()
            })
        },
        restoreFiles({files = [], folders = [], dest = null}) {
            if(files)
                files = Array.isArray(files) ? files : [files]
            if(folders)
                folders = Array.isArray(folders) ? folders : [folders]
            
            dest = dest.replace(PREFIX_RESTORE, '')
            dest = dest !== 'my_files' ? dest : null

            this.$store.dispatch('files/restoreFiles', {
                folderId: dest,
                folders: folders,
                files: files
            })

            return dest
        },
    },

    mounted() {
        eventBus.$on(`update_filter_${this.myFilesModel}`, () => {
            this.resetInfiniteLoading()
            this.$store.commit('files/CLEAR_ALL', this.sourceId)
        })
        eventBus.$on(`detach_file`, (fileId) => {
            const foundIndex = this.attachmentFiles.findIndex(file => fileId === file.id)
            if (foundIndex !== -1) {
                this.attachmentFiles.splice(foundIndex, 1)
            }
        })
    },

    beforeDestroy() {
        eventBus.$off(`update_filter_${this.myFilesModel}`)

        const query = JSON.parse(JSON.stringify(this.$route.query))
        if(query.folder) {
            delete query['folder']
            this.$router.replace({ query })
        }
    },
}
</script>

<style scoped lang="scss">
.files_wrap {
    transition: opacity 0.2s ease;
}
.files_global_spin {
    position: absolute;
    z-index: 2000;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
}
.light_wrap {
    opacity: 0.3;
}
.action_icon {
    &:not(:last-child) {
        margin-right: 0.5rem;
    }
}

</style>

<style lang="scss">
.files_view_type {
    display: flex;
    .ant-radio-button-wrapper{
        border: none;
        font-size: 20px;
        padding: 0 10px;
        &::before{
            display: none;
        }
    }
    .ant-radio-button{
        display: none;
    }
    &.mobile_list{
        .ant-radio-button-wrapper{
            display: flex;
            align-items: center;
            span{
                display: flex;
                align-items: center;
            }
        }
    }
}
.btn_icon {
    line-height: 100%;
}
</style>