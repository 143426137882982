export default {
    props: {
        rootId: {
            type: [String, Number],
            default: ''
        },
        sourceId: {
            type: [String, Number],
            default: ''
        },
    }
}